.App {
  text-align: center;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a{
  color: var(--color-accent);
  text-decoration: none;
}

.section_padding{
  padding: 4rem 6rem;
}

.section_margin{
  margin: 4rem 6rem;
}

.flip-vertical-right {
	-webkit-animation: flip-vertical-right 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-vertical-right 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-19 16:25:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */
 @-webkit-keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}

@media screen and (max-width: 700px) {
  .section_padding{
    padding: 4rem;
  }

  .section_margin{
    padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section_padding{
    padding: 4rem 2rem;
  }

  .section_margin{
    padding: 4rem 2rem;
  }
}


.item-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
}

/*RECIPE PAGE STYLE*/

.recipe-container {
  display: flex;
  justify-content: space-evenly;
  padding: 6em 6em 2.5em 6em;
  gap: 6em;
}

.back {
  text-align: center;
  margin-bottom: 2em;
}