*{
    box-sizing: border-box;
}

.img-container{
    
     width: 50%;
     max-width: 600px;
     border-radius: 10px;
     margin-bottom: 70px;
     border: 1px solid #E8E9EB; 
     flex-shrink: 1;
     height: 356px;
   
}

.img-container img{
    width: 100%;
    height: 356px;
}


.form-card{
    background-color: #daa7a7;
    max-width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    overflow: none;
}

/* style={{ width: "40rem", minHeight: "428px", marginBottom: "100px", backgroundColor: "#daa7a7", marginLeft: "12rem", marginTop: "5rem" }} */

/* more card style style={{ top: "auto", width: "30rem", marginLeft: "50px", marginBottom: "50px", paddingTop: "50px"}} */



.container2{
   
    display: flex;
    flex-direction:row-reverse;
    justify-content:center;
}




@media only screen and (max-width: 660px) {
    .form-card{
        width:400px
    }
}