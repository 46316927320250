#navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding:  4rem 15rem; */
}

/* #navbar_logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} */

#name{
    font-family: var(--font-family);
    padding-left: 1.5rem;
    font-size: 2rem;
}

#logo{
    height: 4rem;
}

/* #navbar_links{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
} */

ul{
    list-style: none;
    display: flex;
    align-content: row;
}

li{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    
}

.linkText{
    font-weight: bolder;
    font-size: 20px;
    color: var(--color-accent);
}