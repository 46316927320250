*{
    box-sizing: border-box;
}

.item-container{
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
}

.recipe-card{
    flex:2 0 21%;;
}

.card-img{
  height: "9px";
  max-width: "280px";
  border: "1px solid #E8E9EB";
 border-radius: "10px";
  /* margin-top: "10px";
 margin-bottom: "10px";  */
}


@media only screen and (max-width: 2050px) {
    .item-container{
        width: 65%;
    }
}

@media only screen and (max-width: 1577px) {
    .item-container{
        width: 87%;
    }
}